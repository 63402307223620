import history from "history/browser";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./components/App";
import type { Config } from "./config";
import { createWebRelay } from "./core/relay";
import { load } from "./core/ssrUtil";
import routes from "./routes";
import { loadQuery } from "react-relay";
import AppContextProviderQuery, {
  type AppContextProviderQuery as AppContextProviderQueryType,
} from "./queries/AppContextProviderQuery.graphql";

const config = load<Config>("config");

if (!config) {
  throw new Error("Config was not found in HTML document!");
}

const relay = createWebRelay();

const initialQueryRef = loadQuery<AppContextProviderQueryType>(
  relay,
  AppContextProviderQuery,
  {},
);

// Render the top-level React component
const container = document.getElementById("root");
if (container) {
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <React.StrictMode>
      <App
        config={config}
        history={history}
        relay={relay}
        routes={routes}
        initialQueryRef={initialQueryRef}
      />
    </React.StrictMode>,
  );
} else {
  console.error("React root was not found in HTML document!");
}
