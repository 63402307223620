import { type Route } from "../core";
import { NotFoundError } from "../core/errors";
import { RouterContext } from "../core/router.types";
import ArticlePageQuery, {
  ArticlePageQuery$data,
  ArticlePageQuery as ArticlePageQueryType,
} from "../queries/ArticlePageQuery.graphql";
import { ArticlePage } from "./ArticlePage";

export default {
  path: "/:articleSlug",
  query: ArticlePageQuery,
  variables(ctx) {
    return {
      articleSlug: ctx.params?.articleSlug,
    };
  },
  component: () => import(/* webpackChunkName: "shop" */ "./ArticlePage"),
  response: (data: ArticlePageQuery$data) => {
    if (data.cmsArticles?.data.length == 0) throw new NotFoundError();
    if ((data.cmsArticles?.data.length ?? 0) > 1)
      console.warn(
        "More than one article found for the slug: " +
          data.cmsArticles?.data[0].attributes?.slug,
      );
    const article = data.cmsArticles?.data[0];
    if (!article) throw new NotFoundError();

    return {
      props: { data },
      title: article.attributes?.seo?.metaTitle ?? article.attributes?.title,
      description:
        article.attributes?.seo?.metaDescription ??
        article.attributes?.description,
      keywords: article.attributes?.seo?.keywords,
      viewPort: article.attributes?.seo?.metaViewport,
      robots: article.attributes?.seo?.metaRobots,
      canonicalURL: article.attributes?.seo?.canonicalURL,
      image: article.attributes?.seo?.metaImage,
      ogCanonicalURL: article.attributes?.seo?.canonicalURL,
      ogTitle: article.attributes?.seo?.metaTitle ?? article.attributes?.title,
      ogDescription:
        article.attributes?.seo?.metaDescription ??
        article.attributes?.description,
      ogType: "website", // TODO: get from seo
      ogImage: article.attributes?.seo?.metaImage,
    };
  },
} as Route<ArticlePage, ArticlePageQueryType>;
